<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div id="events">
    <v-app id="events-app">
      <v-main :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="tutorialApi.isError" :api="tutorialApi" />
        <!-- EOC -->
        <v-container
          style="max-width: 480px; padding: 0; height: 100%; position: relative"
        >
          <!-- BOC: header -->
          <v-row>
            <v-col cols="10" class="mt-1">
              <v-card>
                <v-card-title>{{
                  auth.Student ? auth.Student.name : auth.User.name
                }}</v-card-title>
                <v-card-subtitle class="pb-0" v-if="schoolYear">{{
                  $_parseSchoolYear(schoolYear)
                }}</v-card-subtitle>
                <v-card-subtitle class="pt-0">
                  {{ auth.Player.mochiId }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-end align-start">
              <Setting></Setting>
            </v-col>
          </v-row>
          <!-- EOC -->
          <!-- BOC: notification bar -->
          <div class="d-flex justify-space-between">
            <div>
              <v-img
                :src="require('@/assets/explore/menu/mailbox.png')"
                width="100"
                @click="mail()"
                :class="'menu-item ' + (countMail ? 'shake' : '')"
              >
              </v-img>
            </div>
            <div>
              <v-img
                v-if="settings.debug"
                :src="require('@/assets/explore/menu/tower.png')"
                width="100"
                @click="abc()"
                class="menu-item"
              >
                <div class="floor-text">
                  <span class="text-stroke-sm text-h6">Level 1</span>
                </div>
              </v-img>
            </div>
          </div>
          <!-- EOC -->
          <!-- BOC: body -->
          <v-img contain max-width="480" class="mx-auto">
            <v-carousel
              hide-delimiters
              height="auto"
              class="mb-10"
              :show-arrows="false"
            >
              <v-carousel-item v-for="(item, i) in items" :key="i">
                <div>
                  <v-img
                    max-width="350"
                    :src="item.img"
                    class="mx-auto d-flex align-center justify-center"
                  >
                    <AbstractAvatar
                      :avatar="auth.Player.avatar"
                      :height="100"
                      :width="120"
                      class="mx-auto d-flex align-center justify-center pr-10 pb-5"
                    ></AbstractAvatar>
                  </v-img>
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-img>
          <!-- EOC -->
          <div>
            <div class="d-flex justify-end bottom-nav justify-space-around">
              <div class="menu-button" @click="home()" id="ExploreMenu">
                <v-img
                  :src="require('@/assets/explore/menu/home.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
              <div class="menu-button" @click="collections()">
                <v-img
                  :src="require('@/assets/explore/menu/collection.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
              <div class="menu-button pulse" @click="serise()" id="HamochiCup">
                <v-img
                  :src="require('@/assets/explore/menu/prize.png')"
                  width="150"
                  class="menu-item"
                >
                </v-img>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
      <DebugTool v-if="settings.debug"></DebugTool>
      <AbstractChat
        v-if="tutorialChat && chatStart"
        X1="Hamochie"
        :chats="tutorialChat"
        :callback="callback"
      />
      <SkipTut />
      <newsDialog ref="ref_news" />
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import delay from "delay";
import AvatarSkeleton from "@/components/skeleton/Avatar";
import SettingSkeleton from "@/components/skeleton/Setting";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    Setting: () => ({
      component: import(
        /* webpackChunkName: "component-setting" */ "@/components/Setting"
      ),
      loading: SettingSkeleton,
      delay: 200,
    }),
    DebugTool: () =>
      import(
        /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    AbstractChat: () =>
      import(
        /* webpackChunkName: "component-abstract-chat" */ "@/components/tutorial/AbstractChat"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
    newsDialog: () =>
      import(
        /* webpackChunkName: "component-news-dialog" */ "@/components/news/newsDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
    coin: (state) => state.coin.data,
  }),
  data: () => ({
    intraction: null,
    currentFloor: null,
    openTower: false,
    towerOpenDay: 0,
    goldenbrush: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //BOC:[mail]
    mailApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    countMail: 0,
    //EOC
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    seriesApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    pinCheckerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    schoolYear: null,
    progress: 39,
    items: [
      {
        img: require("@/assets/explore/fishing/boat.png"),
        icon: require("@/assets/explore/fishing/boat.png"),
      },
      // {
      //   img: require("@/assets/explore/kombat/dock.png"),
      //   icon: require("@/assets/explore/kombat/dock.png"),
      // },
      // {
      //   img: require("@/assets/explore/foraging/tree.png"),
      //   icon: require("@/assets/explore/mining/icon.png"),
      // },
      // {
      //   img: require("@/assets/explore/mining/cave.png"),
      //   icon: require("@/assets/explore/mining/icon.png"),
      // },
    ],
    navs: [
      {
        bg: require("@/assets/explore/menu/nav_1.png"),
        icon: require("@/assets/explore/menu/email.png"),
        text: "new mail!",
      },
      {
        bg: require("@/assets/explore/menu/nav_2.png"),
      },
      {
        bg: require("@/assets/explore/menu/nav_3.png"),
      },
      {
        bg: require("@/assets/explore/menu/nav_4.png"),
      },
      {
        bg: require("@/assets/explore/menu/nav_5.png"),
      },
      {
        bg: require("@/assets/explore/menu/nav_6.png"),
      },
    ],
    chatStart: false,
    tutorialChat: null,
    tutExploreIntroChats: [],
    tutExploreEventsChats: [],
    tutExploreShopChats: [],
    events: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var coins = null;
      coins = resp.filter(function (entry) {
        return entry.Item.key == "goldcoin";
      });
      var goldenbrush = null;
      goldenbrush = resp.filter(function (entry) {
        return entry.Item.key == "goldenbrush";
      });
      if (goldenbrush[0]) {
        this.goldenbrush = goldenbrush[0].Inventory.quantity;
      } else {
        this.goldenbrush = 0;
      }
      if (coins[0]) {
        this.coins = coins[0].Inventory.quantity;
      } else {
        this.coins = 0;
      }
      this.$store.commit("updateCoin", {
        Goldenbrush: { quantity: this.goldenbrush },
        Currency: { quantity: this.coins },
      });
      this.api.params = {
        countryCode: this.auth.Account.countryCode,
      };
      (async () => {
        await delay(200);
        this.$api.fetch(this.seriesApi);
      })();
    };

    //BOC:[mailApi]
    this.mailApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/mail/count";

    this.mailApi.callbackReset = () => {
      this.mailApi.isLoading = true;
      this.mailApi.isError = false;
    };

    this.mailApi.callbackError = (e) => {
      this.mailApi.isLoading = false;
      this.mailApi.isError = true;
      this.mailApi.error = e;
    };

    this.mailApi.callbackSuccess = (resp) => {
      this.mailApi.isLoading = false;
      this.countMail = resp;
    };
    //EOC
    //BOC:[pinCheckerApi]
    this.pinCheckerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/player/checkPinUpdate";

    this.pinCheckerApi.callbackReset = () => {
      this.pinCheckerApi.isLoading = true;
      this.pinCheckerApi.isError = false;
    };

    this.pinCheckerApi.callbackError = (e) => {
      this.pinCheckerApi.isLoading = false;
      this.pinCheckerApi.isError = true;
      this.pinCheckerApi.error = e;
    };

    this.pinCheckerApi.callbackSuccess = (resp) => {
      this.pinCheckerApi.isLoading = false;
      if (resp.shouldLogout) {
        this.$router.push({ name: "PageMainLogout" });
      }
    };
    //EOC
    //BOC:[gameApi]
    this.gameApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/dailyGame/visit";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };

    this.gameApi.callbackSuccess = (resp) => {
      this.gameApi.isLoading = false;
      this.currentFloor = resp.Game.currentFloor;
    };
    //EOC
    this.seriesApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/series";

    this.seriesApi.callbackReset = () => {
      this.seriesApi.isLoading = true;
      this.seriesApi.isError = false;
    };

    this.seriesApi.callbackError = (e) => {
      this.seriesApi.isLoading = false;
      this.seriesApi.isError = true;
      this.seriesApi.error = e;
    };
    this.seriesApi.callbackSuccess = (resp) => {
      this.seriesApi.isLoading = false;
      this.events = resp.Event;
      this.checkIsTowerOpen();
      this.$api.fetch(this.tutorialApi);
    };

    this.tutorialApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/tutorial/browse";
    this.tutorialApi.callbackReset = () => {
      this.tutorialApi.isLoading = true;
      this.tutorialApi.isError = false;
      this.tutorialApi.error = null;
    };
    this.tutorialApi.callbackError = (e) => {
      this.tutorialApi.isLoading = false;
      this.tutorialApi.isError = true;
      this.tutorialApi.error = e;
    };
    this.tutorialApi.callbackSuccess = (resp) => {
      this.tutorialApi.isLoading = false;
      if (resp.Tutorial) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        if (this.tutorial) {
          tempTut.Tutorial = resp.Tutorial;
        } else {
          tempTut = resp;
        }
        this.$store.commit("updateTutorial", tempTut);
      } else {
        this.$store.dispatch("resetTutorial");
      }

      // Check Tutorials
      var tutExploreIntro = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreIntro";
      });
      var tutExploreEvents = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreEvents";
      });
      var tutExploreShop = this.tutorial.Tutorial.filter(function (entry) {
        return entry.key == "tutExploreShop";
      });

      // #tutExploreIntro - start the tutorial
      if (tutExploreIntro.length == 0) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreIntro";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreIntroChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      }
      // #tutExploreEvents - events tutorial starts
      else if (
        tutExploreEvents.length == 0 &&
        this.events.find(
          (element) =>
            this.status(element.timestampStart, element.timestampEnd) ==
            "ONGOING"
        )
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreEvents";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreEventsChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      }
      // #tutExploreShop - shop tutorial starts
      else if (
        tutExploreShop.length == 0 &&
        this.coin.Currency.quantity &&
        this.coin.Currency.quantity > 0
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 1;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreShopChats),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      } else {
        this.$refs["ref_news"].fetch();
        this.$api.fetch(this.mailApi);
      }
    };
  },
  mounted() {
    this.$api.fetch(this.pinCheckerApi);

    this.tutExploreIntroChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_1"),
        sound: "sfx_chat_1",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_2"),
        sound: "sfx_chat_3",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreIntro.dialog_3"),
        sound: "sfx_chat_2",
      },
    ];

    this.tutExploreEventsChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreEvents.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];

    this.tutExploreShopChats = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_1"),
        sound: "sfx_chat_2",
      },
    ];

    this.$api.fetch(this.api);
    // this.loadTutorial();
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    }else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } 
    else {
      this.schoolYear = this.auth.User.schoolYear;
    }
    this.gameApi.params = {
      schoolYear: this.schoolYear,
    };

    this.$api.fetch(this.gameApi);
  },
  methods: {
    collections() {
      this.$router.push({
        name: "PageExploreCollections",
      });
    },
    serise() {
      this.$intro().exit();
      this.$router.push({
        name: "PageExploreSeries",
      });
    },
    home() {
      this.$intro().exit();
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    mail() {
      this.$router.push({
        name: "PageExploreMail",
      });
    },
    abc() {
      this.$router.push({
        name: "PageTestingExploreABC",
      });
    },
    loadTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/browse";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        if (resp.Tutorial) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          if (this.tutorial) {
            tempTut.Tutorial = resp.Tutorial;
          } else {
            tempTut = resp;
          }
          this.$store.commit("updateTutorial", tempTut);
        } else {
          this.$store.dispatch("resetTutorial");
        }
      };
      this.$api.fetch(this.tutorialApi);
    },
    status(timestampStart, timestampEnd) {
      if (this.$moment().isAfter(timestampEnd)) {
        return "PAST";
      } else if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else if (
        this.$moment(timestampStart).diff(this.$moment(), "days") > 30
      ) {
        return "LONGUPCOMING";
      } else {
        return "UPCOMING";
      }
    },
    checkIsTowerOpen() {
      // let event = this.events.find(
      //   (element) =>
      //     this.status(element.timestampStart, element.timestampEnd) == "ONGOING"
      // );
      const eventStartDate = this.$moment("2023-12-18 07:00");
      const currentDate = this.$moment();
      const daysDifference = currentDate.diff(eventStartDate, "days");

      if (daysDifference >= 0) {
        this.openTower = true;
      } else {
        this.openTower = false;
        this.towerOpenDay = 7 - daysDifference;
      }

      // else{
      //   this.openTower = true;
      // }
      if (this.settings.debug) {
        this.openTower = true;
      }
    },
    callback() {
      this.chatStart = false;
      // #tutExploreIntro - after the intrduction finish, update tutorial
      if (this.tutorial.Ongoing == "tutExploreIntro") {
        this.tutorialApi.url =
          this.$api.servers.game +
          "/api/v1/" +
          this.$_getLocale() +
          "/game/tutorial/complete";
        this.tutorialApi.callbackReset = () => {
          this.tutorialApi.isLoading = true;
          this.tutorialApi.isError = false;
          this.tutorialApi.error = null;
        };
        this.tutorialApi.callbackError = (e) => {
          this.tutorialApi.isLoading = false;
          this.tutorialApi.isError = true;
          this.tutorialApi.error = e;
        };
        this.tutorialApi.callbackSuccess = (resp) => {
          this.tutorialApi.isLoading = false;
          this.$store.commit("updateTutorial", resp);

          // #tutExploreEvents - after update intro tutorial triger event tutorial
          var tutExploreEvents = this.tutorial.Tutorial.filter(function (
            entry
          ) {
            return entry.key == "tutExploreEvents";
          });
          if (tutExploreEvents.length == 0) {
            if (
              this.events.find(
                (element) =>
                  this.status(element.timestampStart, element.timestampEnd) ==
                  "ONGOING"
              )
            ) {
              let tempTut = this.$_.cloneDeep(this.tutorial);
              tempTut.Ongoing = "tutExploreEvents";
              tempTut.Step = 1;
              this.$store.commit("updateTutorial", tempTut);

              this.intraction = "pointer-events: none;";
              setTimeout(
                () => (
                  (this.tutorialChat = this.tutExploreEventsChats),
                  (this.chatStart = true),
                  (this.intraction = "pointer-events:all")
                ),
                800
              );
            }
          }
        };
        this.tutorialApi.params = {
          key: "tutExploreIntro",
        };
        this.$api.fetch(this.tutorialApi);
      }

      // #tutExploreEvents - after the event tutorial intrduction finish it will triger introjs to gide user to click hamochi cup
      else if (
        this.tutorial.Ongoing == "tutExploreEvents" &&
        this.tutorial.Step == 1
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreEvents";
        tempTut.Step = 2;
        this.$store.commit("updateTutorial", tempTut);

        // check the route if user in town page it will triger the introjs
        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#HamochiCup"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreEvents.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }

      // #tutExploreShop - after the shop tutorial intrduction finish it will triger introjs to gide user to click menu button
      else if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 1
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 2;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#ExploreMenu"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_1.png") +
                    '">' +
                    this.$t("tutorial.exploreShop.dialog_2"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }
    },
  },
};
</script>
  
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.pulse:hover {
  animation: none;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
  margin: 10px;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.shake {
  -webkit-animation: shake 2s infinite ease-in-out;
  -moz-animation: shake 2s infinite ease-in-out;
  -ms-animation: shake 2s infinite ease-in-out;
  -o-animation: shake 2s infinite ease-in-out;
  animation: shake 2s infinite ease-in-out;
}
@keyframes shake {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

#events {
  background-color: rgba(255, 228, 181, 0.3);
  /*background-color: #c7e5e9;*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#events-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.energy-panel {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(30% - 10px) !important;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  padding: 0 5px;
  justify-content: space-between;
}

.nav {
  width: 16.66%;
  padding: 0 2px;
}

.nav-panel {
  display: flex;
  padding: 5px 10px;
}

.nav-text {
  font-size: small;
  text-align: center;
  line-height: 12px;
  margin-top: -10px;
  color: #fff;
  position: relative;
  text-shadow: rgb(75, 75, 75) 3px 0px 0px,
    rgb(75, 75, 75) 2.83487px 0.981584px 0px,
    rgb(75, 75, 75) 2.35766px 1.85511px 0px,
    rgb(75, 75, 75) 1.62091px 2.52441px 0px,
    rgb(75, 75, 75) 0.705713px 2.91581px 0px,
    rgb(75, 75, 75) -0.287171px 2.98622px 0px,
    rgb(75, 75, 75) -1.24844px 2.72789px 0px,
    rgb(75, 75, 75) -2.07227px 2.16926px 0px,
    rgb(75, 75, 75) -2.66798px 1.37182px 0px,
    rgb(75, 75, 75) -2.96998px 0.42336px 0px,
    rgb(75, 75, 75) -2.94502px -0.571704px 0px,
    rgb(75, 75, 75) -2.59586px -1.50383px 0px,
    rgb(75, 75, 75) -1.96093px -2.27041px 0px,
    rgb(75, 75, 75) -1.11013px -2.78704px 0px,
    rgb(75, 75, 75) -0.137119px -2.99686px 0px,
    rgb(75, 75, 75) 0.850987px -2.87677px 0px,
    rgb(75, 75, 75) 1.74541px -2.43999px 0px,
    rgb(75, 75, 75) 2.44769px -1.73459px 0px,
    rgb(75, 75, 75) 2.88051px -0.838247px 0px;
}

.menu-button {
  cursor: pointer;
  width: 25%;
  display: flex;
  justify-content: center;
}

.bottom-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.floor-text {
  padding-top: 60px;
  text-align: center;
  color: black;
  /* font-size: 20px;
  -webkit-text-stroke: 0.5px white !important; */
}
.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}
.disabled-image {
  filter: grayscale(100%) !important;
}
</style>