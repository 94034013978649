<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            Mini Games
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5">
            <div
              class="pa-1 d-flex flex-wrap justify-space-between"
              style="position: relative"
              v-if="miniGame"
            >
              <div
                class="level-cart"
                v-for="(data, i) in miniGame.data"
                :key="i"
                @click="play(i)"
              >
                <div class="level-img">
                  <div class="container-1x1">
                    <div class="aspect-ratio-item d-flex" v-if="data">
                      <v-img
                        :src="require('@/assets/miniGames/gem.png')"
                        width="150"
                        contain
                      ></v-img>
                    </div>
                    <div class="aspect-ratio-item d-flex align-end" v-else>
                      <v-img
                        :src="require('@/assets/miniGames/rock.webp')"
                        width="150"
                        contain
                      ></v-img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    miniGame: (state) => state.miniGame.data,
  }),
  props: [
    //
  ],
  data: () => ({}),
  created() {},
  mounted() {
    let tempMiniGame;
    if (
      (this.miniGame && !this.isToday(new Date(this.miniGame.date))) ||
      !this.miniGame
    ) {
      tempMiniGame = {
        date: new Date(),
        data: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
        },
      };
      this.$store.commit("updateMiniGame", tempMiniGame);
    }
    console.log(this.miniGame)
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding-Dev",
      });
    },
    play(i) {
      this.$router.push({
        name: "PageMiniGamesStart",
        params: {
          game: i,
        },
      });
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
  },
};
</script>

<style scoped>
.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.level-cart {
  padding: 10px;
  margin-bottom: 12px;
  width: calc((100% / 3) - 8px);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.level-img {
  background-color: rgb(215, 225, 215);
}

.no-boss {
  filter: brightness(0);
  opacity: 50%;
}

.container-1x1 {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>